// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, setPersistence, browserLocalPersistence } from "firebase/auth";

const isStaging = process.env.VUE_APP_ENVIRONMENT === 'staging';

// Select the correct set of variables
const firebaseConfig = isStaging ? {
    apiKey: process.env.VUE_APP_STAGING_API_KEY,
    authDomain: process.env.VUE_APP_STAGING_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_STAGING_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STAGING_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_STAGING_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_STAGING_APP_ID,
    measurementId: process.env.VUE_APP_STAGING_MEASUREMENT_ID,
} : {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_APP_ID,
    measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

//initialize firebase auth
const auth = getAuth()

setPersistence(auth, browserLocalPersistence);

// Track the Auth state
// Function to track the Auth state
const authStateConfirmed = new Promise(resolve => {
    onAuthStateChanged(auth, user => {
        resolve(user);
    });
});

export { app, auth, authStateConfirmed }