<template>
  <footer :style="{ backgroundColor: '#262626' }" class="shadow fixed bottom-0 left-0 w-full z-50">
    <div class="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
      <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 <a
          href="https://flowbite.com/"
          class="hover:underline"
      >Wysper™</a>. All Rights Reserved.
      </span>
      <ul class="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
<!--        <li>-->
<!--          <a-->
<!--              href="#"-->
<!--              class="mr-4 hover:underline md:mr-6 "-->
<!--          >About</a>-->
<!--        </li>-->
<!--        <li>-->
<!--          <a-->
<!--              href="#"-->
<!--              class="mr-4 hover:underline md:mr-6"-->
<!--          >Privacy Policy</a>-->
<!--        </li>-->
<!--        <li>-->
<!--          <a-->
<!--              href="#"-->
<!--              class="mr-4 hover:underline md:mr-6"-->
<!--          >Licensing</a>-->
<!--        </li>-->
<!--        <li>-->
<!--          <a-->
<!--              href="#"-->
<!--              class="hover:underline"-->
<!--          >Contact</a>-->
<!--        </li>-->
      </ul>
    </div>
  </footer>
</template>

<style scoped>
footer {
  box-sizing: border-box;
}
</style>
