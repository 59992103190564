<template>
  <router-link
      :to="to"
      v-slot="{ navigate }"
  >
    <DisclosureButton
        @click="navigate"
        :class="[isActive.value ? activeClass : '', customClass]"
        :aria-current="isActive.value ? 'page' : undefined"
    >
      <slot></slot>
    </DisclosureButton>
  </router-link>
</template>

<script>
import { DisclosureButton } from '@headlessui/vue'
import { useRouter, useRoute } from 'vue-router'
import { computed, ref } from 'vue'

export default {
  components: {
    DisclosureButton
  },
  props: {
    to: {
      type: String,
      required: true
    },
    customClass: {
      type: String,
      default: 'block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white'
    },
    activeClass: {
      type: String,
      default: 'block rounded-md px-3 py-2 text-base font-medium bg-gray-900 menu-text-white!important'
    },
  },
  setup(props) {
    const router = useRouter()
    const route = useRoute()

    const isActive = computed(() => route.path === props.to)

    return {
      isActive,
      router,
      route
    }
  }
}
</script>
