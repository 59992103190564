<template>
  <div>
    <Navbar />
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import { provide, reactive, onMounted, watch, ref } from 'vue'
import { auth, authStateConfirmed } from "../firebaseConfig";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { useRoute } from 'vue-router';
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";

export default {
  name: 'App',
  components: {
    Footer,
    Navbar
  },
  setup() {
    const hasRegistered = ref(false);
    const user = reactive({ value: null });
    const route = useRoute();

    // Provide the hasRegistered ref for child components
    provide('hasRegistered', hasRegistered);

    async function loadUserRegistrationStatus(currentUser) {
      if (currentUser) {
        const db = getFirestore();
        const docRef = doc(db, "users", currentUser.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists() && docSnap.data().hasRegistered !== undefined) {
          hasRegistered.value = docSnap.data().hasRegistered;
          // Set in local storage
          localStorage.setItem('hasRegistered', hasRegistered.value);
        } else {
          hasRegistered.value = false;  // Set a default value if undefined
          localStorage.setItem('hasRegistered', hasRegistered.value); // Set in local storage
        }
      }
    }

    onMounted(() => {
      auth.onAuthStateChanged(async (currentUser) => {
        user.value = currentUser;
        if (currentUser) {
          window.analytics.identify(currentUser.uid, {
            email: currentUser.email
          });
          await loadUserRegistrationStatus(currentUser);
        }
      });
    });

    // Set up a watcher on the $route object
    watch(() => route, () => {
      if (user.value) {
        loadUserRegistrationStatus(user.value);
      }
    }, { deep: true });

    return { hasRegistered, user }
  }
}
</script>

<style>
</style>
