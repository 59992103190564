import { createRouter, createWebHistory } from 'vue-router'
import { auth, authStateConfirmed } from "../firebaseConfig";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { inject } from 'vue'
import axios from 'axios';

const routes = [
    {
        path: '/',
        name: 'Generate',
        component: () => import('./views/Generate.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/transcripts',
        name: 'Transcripts',
        component: () => import('./views/Transcripts.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/transcripts/:id',
        name: 'TranscriptionDetail',
        component: () => import('./views/TranscriptionDetail.vue'),
        props: true,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('./views/Login.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('./views/Register.vue')
    },
    {
        path: '/register-appsumo',
        name: 'RegisterAppsumo',
        component: () => import('./views/RegisterAppsumo.vue')
    },
    {
        path: '/confirm-registration',
        name: 'ConfirmRegistration',
        component: () => import('./views/ConfirmRegistration.vue'),
    },
    {
        path: '/choose-plan',
        name: 'ChoosePlan',
        component: () => import('./views/ChoosePlan.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/account',
        name: 'AccountSettings',
        component: () => import('./views/Account/AccountSettings.vue'),
        meta: {
            requiresAuth: true
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

let lastPingTime = 0;
const debounceTime = 300000;

router.beforeEach(async (to, from, next) => {
    // Wait for auth state to be confirmed
    await authStateConfirmed;

    const currentTime = Date.now();
    if (currentTime - lastPingTime > debounceTime) {
        lastPingTime = currentTime;
        // Non-blocking ping call
        axios.get(`${process.env.VUE_APP_API_URL}/ping`).then(() => {
            console.log('Backend pinged successfully');
        }).catch((error) => {
            console.error('Error pinging backend:', error);
        });
    }

    const currentUser = auth.currentUser;
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const hasRegistered = localStorage.getItem('hasRegistered') === 'true';

    // Allow authenticated users to proceed to /confirm-registration if hasRegistered is false
    if (to.path === '/confirm-registration' && currentUser && !hasRegistered) {
        next();
        return; // Prevent further execution
    }

    // If navigating to /register-appsumo, log the user out
    if (to.path === '/register-appsumo' && currentUser) {
        await auth.signOut();
        setTimeout(() => {
            next(); // Proceed to the route after signing out
        }, 1000); // 1000 milliseconds = 1 second
    } else if (!requiresAuth && currentUser) {
        next('/');
    } else {
        if (currentUser) {
            if (hasRegistered === false && to.path == '/choose-plan-wysper') {
                next('/choose-plan-wysper');
            }
            else if (hasRegistered === false && to.path !== '/choose-plan') {
                next('/choose-plan');
            } else {
                next();
            }
        } else {
            next();
        }
    }
});


export default router
