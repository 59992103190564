<template>
  <Disclosure
    v-slot="{ open }"
    as="nav"
    :style="{ backgroundColor: '#262626' }"
  >
    <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
      <div class="relative flex h-16 items-center justify-between">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <DisclosureButton class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
            <span class="sr-only">Open main menu</span>
            <Bars3Icon
              v-if="!open"
              class="block h-6 w-6"
              aria-hidden="true"
            />
            <XMarkIcon
              v-else
              class="block h-6 w-6"
              aria-hidden="true"
            />
          </DisclosureButton>
        </div>
        <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex flex-shrink-0 items-center">
            <img
              class="logo-image block h-8 w-auto lg:hidden"
              :src="require('../assets/wysper_full_white.png')"
              alt="Wysper"
              @click="navigateHome()"
            >
            <img
              class="logo-image hidden h-8 w-auto lg:block"
              :src="require('../assets/wysper_full_white.png')"
              alt="Wysper"
              @click="navigateHome()"
            >
          </div>
          <div class="hidden sm:ml-6 sm:block">
            <div class="flex space-x-4">
              <router-link
                v-if="!isLoggedIn"
                to="/login"
                class="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                active-class="bg-gray-900 menu-text-white"
              >
                Login
              </router-link>
              <router-link
                v-if="!isLoggedIn"
                to="/register"
                class="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                active-class="bg-gray-900 menu-text-white"
              >
                Register
              </router-link>
              <router-link
                v-if="isLoggedIn && hasRegistered"
                to="/"
                class="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                active-class="bg-gray-900 menu-text-white"
              >
                Generate
              </router-link>
              <router-link
                v-if="isLoggedIn && hasRegistered"
                to="/transcripts"
                class="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                active-class="bg-gray-900 menu-text-white"
              >
                Content
              </router-link>
            </div>
          </div>
        </div>
        <div
          v-if="isLoggedIn"
          class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
        >
          <!-- Profile dropdown -->
          <router-link
                v-if="isLoggedIn && hasRegistered && isTrialUser"
                to="/"
                @click.prevent="openPlanPortal"
                class="px-4 mr-4 text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-md py-2 text-sm font-medium"
                active-class="bg-gray-900 menu-text-white"
              >
                Upgrade
              </router-link>
          <Menu
            as="div"
            class="relative ml-3"
          >
            <div>
              <MenuButton class="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                <span class="sr-only">Open user menu</span>
                <div class="w-7 h-7 rounded-full border-2 border-white flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="white"
                    class="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                    />
                  </svg>
                </div>
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <MenuItem
                  v-if="isLoggedIn && hasRegistered"
                  v-slot="{ active }"
                >
                  <router-link
                    to="/account"
                    :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                  >
                    Account
                  </router-link>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                    @click.prevent="openSupportEmail"
                  >
                    Support
                  </a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                    @click.prevent="openFAQPage"
                  >
                    FAQ
                  </a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                    @click="logout"
                  >
                    Sign out
                  </a>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden">
      <div class="space-y-1 px-2 pb-3 pt-2">
        <CustomDisclosureButton
          v-if="!isLoggedIn"
          to="/login"
        >
          Login
        </CustomDisclosureButton>
        <CustomDisclosureButton
          v-if="!isLoggedIn"
          to="/register"
        >
          Register
        </CustomDisclosureButton>
        <CustomDisclosureButton
          v-if="isLoggedIn"
          to="/"
        >
          Generate
        </CustomDisclosureButton>
        <CustomDisclosureButton
          v-if="isLoggedIn"
          to="/transcriptions"
        >
          Transcriptions
        </CustomDisclosureButton>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import { computed, ref, onMounted } from "vue";
import { auth } from "../../firebaseConfig";
import CustomDisclosureButton from "@/components/CustomDisclosureButton";
import { inject } from 'vue'
import {useRouter} from "vue-router/dist/vue-router";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import axios from 'axios';



export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Bars3Icon,
    XMarkIcon,
    CustomDisclosureButton
  },
  setup() {
    const open = ref(false);
    const user = ref(null);
    const isTrialUser = ref(false);
    const isLoggedIn = computed(() => !!user.value);
    const hasRegistered = inject('hasRegistered');
    const router = useRouter()
    const db = getFirestore();
    const customerId = ref(null);


    onMounted(() => {
      const initialize = async () => {
        auth.onAuthStateChanged((currentUser) => {
          user.value = currentUser;
        });

        setTimeout(async () => {
          const authInstance = getAuth();
          if (authInstance.currentUser) {      
            const userRef = doc(db, "users", authInstance.currentUser.uid);
            let docSnap = await getDoc(userRef);

            if (docSnap.exists() && docSnap.data().trialUser) {
                isTrialUser.value = true;
                console.log('User is a trial user');
            }


          const docRef = doc(db, "users", authInstance.currentUser.uid, "Plan", "current");
          docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
              const plan = docSnap.data();
              customerId.value = plan.customer_id;

              console.log('Customer ID Value Testing');
              console.log(customerId.value);
            }
          }
        }, 1000);
    };

  initialize();
});

    const logout = async () => {
      try {
        await auth.signOut();
        router.push('/login');
      } catch (error) {
        console.error('Error signing out:', error);
      }
    };

    const openSupportEmail = () => {
      window.open('https://share.hsforms.com/16yqHTQ_hQWOUsZTaaMWsCwpx8p2', '_blank');
    };

    const openFAQPage = () => {
      window.open('https://www.wysper.ai/faq', '_blank');
    };

    const navigateHome = () => {
      router.push('/');
    };

    async function openPlanPortal() {
      if (customerId.value) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/customer-portal/${customerId.value}`);
          window.location.href = response.data.url;
        } catch (error) {
          console.error("Error opening customer portal:", error);
        }
      }
    };

    return {
      open,
      isLoggedIn,
      logout,
      hasRegistered,
      navigateHome,
      openSupportEmail,
      openFAQPage,
      isTrialUser,
      openPlanPortal,
      customerId
    };
  },
};
</script>


<style scoped>
.menu-text-white {
  color: white !important;
}

.logo-image {
  cursor: pointer;
}

@media screen and (min-width: 1024px) {
  .nav-menu {
    display: none;
  }
}
</style>
